import { navigate } from "gatsby";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PostPurchaseSurvey from "../../../components/checkout/confirmation/PostPurchaseSurvey";
import OrderDetails from "../../../components/checkout/OrderDetails";
import Referral from "../../../components/checkout/Referral";
import SetPassword from "../../../components/checkout/SetPassword";
import SMSSignup from "../../../components/checkout/SMSSignup";
import useVariation from "../../../hooks/useVariation";
import intl from "../../../services/intl";
import { fetchCart } from "../../../store/cart/actions";
import { getCookie } from "../../../utils/cookies";
import fetchInternal from "../../../utils/fetch";
import metrics from "../../../utils/metrics";
import { Font, responsive } from "../../../utils/style";
import {
  trackOrderCompleted,
  trackSubscribe,
} from "../../../utils/tracking/cart";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  margin-top: 52px;
  padding: 40px 0;

  ${responsive.md`
    margin-top: 64px;
    padding: 80px 0;
  `}
`;

const PageTitle = styled.section`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--between-components-modules-spacing-15, 24px);

  h1 {
    color: #142b6f;
    text-align: center !important;
    ${Font.circular};
    font-style: normal;
    font-weight: 400 !important;
    letter-spacing: -0.8px;

    font-size: 32px;
    line-height: 40px; /* 125% */

    ${responsive.md`
      font-size: 40px;
      line-height: 50px; /* 125% */
    `}
  }

  p {
    color: #0c2b73;
    text-align: center;
    ${Font.dutch};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    max-width: 512px;
  }
`;

// Mark cart as purchased with an API call to the backend, using the cart_id from session storage
const markCartAsPurchased = async () => {
  const cartId = sessionStorage.getItem("rit-cart_id");

  const checkoutType = sessionStorage.getItem("rit-checkout");
  const afterPurchaseAPIPath =
    checkoutType === "home"
      ? "purchase/finalize"
      : "express_checkout/mark_purchased";
  await fetchInternal(afterPurchaseAPIPath, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
    body: JSON.stringify({ cart_id: cartId }),
  });
};

const CheckoutConfirmationPage = (props) => {
  const dispatch = useDispatch();
  const ppsCookie = getCookie("rit_survey");
  const showPostPurchaseSurvey =
    useVariation("post-purchase-survey") && !ppsCookie;

  useEffect(() => {
    const checkoutTypeHome = sessionStorage
      .getItem("rit-checkout")
      ?.includes("home");

    markCartAsPurchased().then(() => {
      if (checkoutTypeHome) {
        dispatch(fetchCart());
      }
    });

    // Remove payment intent from session storage now that the purchase was successful
    sessionStorage.removeItem("rit-payment_intent_client_secret");

    const orderNumber = sessionStorage.getItem("rit-order_number");
    const subscription_id = sessionStorage.getItem("rit-subscription_id");

    // TODO: Refactor this with Cart data from markCartAsPurchased() above.
    const purchasedCart = JSON.parse(
      sessionStorage.getItem("rit-purchased_cart"),
    );
    let purchasedCartProducts = JSON.parse(
      sessionStorage.getItem("rit-purchased_cart_products"),
    );
    const appliedPromotionSession = sessionStorage.getItem(
      "rit-applied_promotion",
    );
    const appliedPromotion =
      appliedPromotionSession && appliedPromotionSession !== "undefined"
        ? JSON.parse(appliedPromotionSession)
        : null;

    const marketingPreference = sessionStorage
      .getItem("rit-marketing_preference")
      ?.includes("true");

    if (orderNumber) {
      trackOrderCompleted(
        orderNumber,
        purchasedCart,
        purchasedCartProducts,
        appliedPromotion,
        {
          addMarketingPreference: true,
          marketingPreference,
        },
      );

      if (subscription_id) {
        trackSubscribe(subscription_id, purchasedCart, purchasedCartProducts);
      } else if (checkoutTypeHome) {
        const currency = purchasedCart.currency.toUpperCase();

        const recurringBillingDetails = Object.keys(
          purchasedCart?.recurring_billing_details,
        );
        recurringBillingDetails?.forEach((key) => {
          const { amount } = purchasedCart.recurring_billing_details[key];
          metrics.track("Subscribe", {
            value: amount / 100,
            currency,
          });
        });
      }
    }

    const clearSessionStorage = () => {
      sessionStorage.removeItem("rit-cart_id");
      sessionStorage.removeItem("rit-marketing_preference");
      sessionStorage.removeItem("rit-reset_password_token");
      sessionStorage.removeItem("rit-express_checkout_confirm_event");
      sessionStorage.removeItem("rit-purchased_cart");
      sessionStorage.removeItem("rit-purchased_cart_products");
      sessionStorage.removeItem("rit-order_number");
      sessionStorage.removeItem("rit-subscription_id");
    };

    window.addEventListener("beforeunload", clearSessionStorage);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, [dispatch]);

  if (typeof window === "undefined") {
    return null;
  }

  const cartId = sessionStorage.getItem("rit-cart_id");

  const expressCheckoutConfirmEvent = JSON.parse(
    sessionStorage.getItem("rit-express_checkout_confirm_event"),
  );

  if (!cartId || !expressCheckoutConfirmEvent) {
    navigate("/cart");
    return null;
  }

  const resetPasswordToken = sessionStorage.getItem("rit-reset_password_token");
  const orderNumber = sessionStorage.getItem("rit-order_number");
  const purchasedCart = JSON.parse(
    sessionStorage.getItem("rit-purchased_cart"),
  );
  let purchasedCartProducts = JSON.parse(
    sessionStorage.getItem("rit-purchased_cart_products"),
  );

  return (
    <Wrapper>
      <div className="container">
        {/* Welcome Header */}
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 mb-5 mb-md-7">
            <PageTitle>
              <h1>{intl.t("checkout.confirmation.title")}</h1>
              <p>
                {intl.t("checkout.confirmation.copy", "", {
                  email: expressCheckoutConfirmEvent.billingDetails.email,
                })}
              </p>
            </PageTitle>
          </div>
        </div>
        {/* Welcome Header */}

        {/* Set Password */}
        {resetPasswordToken && (
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2">
              <SetPassword
                email={expressCheckoutConfirmEvent.billingDetails.email}
                token={resetPasswordToken}
              />
            </div>
          </div>
        )}

        {/* Order Details */}
        <div className="row mb-3">
          <div className="col-12 col-sm-8 offset-sm-2">
            <OrderDetails
              orderNumber={orderNumber}
              cart={purchasedCart}
              cartProducts={purchasedCartProducts}
              shippingAddress={expressCheckoutConfirmEvent.shippingAddress}
              paymentType={expressCheckoutConfirmEvent.expressPaymentType}
            />
          </div>
        </div>

        {/* SMS Signup + Map */}
        <div className="row mb-3">
          <div className="col-12 col-sm-8 offset-sm-2">
            <SMSSignup
              address={expressCheckoutConfirmEvent.shippingAddress.address}
            />
          </div>
        </div>

        {/* Referral */}
        {/* Does not show for en-GB */}
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2">
            <Referral />
          </div>
        </div>
      </div>
      {showPostPurchaseSurvey && <PostPurchaseSurvey />}
    </Wrapper>
  );
};

export default CheckoutConfirmationPage;
