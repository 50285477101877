import styled from "styled-components";
import intl from "../../services/intl";

import { Font, responsive } from "../../utils/style";

// Icons
import applePayLogo from "../../../static/svg/icon-checkout-apple-pay.svg";
import googlePayLogo from "../../../static/svg/icon-checkout-google-pay.svg";
import linkLogo from "../../../static/svg/icon-checkout-link.svg";
import cardLogo from "../../../static/svg/icon-checkout-card.svg";
import OrderItem from "./OrderItem";

const paymentLogos = {
  apple_pay: applePayLogo,
  google_pay: googlePayLogo,
  link: linkLogo,
  card: cardLogo,
};

const SummaryContainer = styled.div`
  display: flex;
  padding: var(--between-components-modules-spacing-1, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--between-components-modules-spacing-1, 16px);
  border-radius: var(--between-components-only-spacing-025, 4px);
  border: 1px solid var(--solid-neutral-cool-100, #c3cfd5);
  background: var(--solid-neutral-cool-20, #f5f7f8);

  ${responsive.md`
    gap: var(--between-components-modules-spacing-15, 24px);
    padding: var(--between-components-modules-spacing-2, 32px);
  `}

  h2 {
    display: inline-flex !important;
    ${Font.circular};
    color: #0c2b73;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 30px; /* 125% */
    letter-spacing: -0.24px;

    width: 100%;
    border-bottom: 0.5px solid var(--solid-neutral-cool-100, #c3cfd5);

    font-size: 16px;
    padding-bottom: var(--between-components-modules-spacing-1, 16px);

    ${responsive.md`
      font-size: 24px;
      padding-bottom: var(--between-components-modules-spacing-15, 24px);
    `}

    span {
      margin-left: 8px;
      color: var(--solid-primary-indigo-blue-100, #142b6f);
      ${Font.circular};
      font-style: normal;
      font-weight: 450;
      font-size: 12px;
      line-height: 18px;
      border-radius: var(--between-components-only-spacing-025, 4px);
      background: var(--solid-primary-yellow-100, #ffd600);
      display: flex;
      align-items: center;
      flex-basis: fit-content;

      padding: var(--between-components-only-spacing-025, 4px)
        var(--between-components-only-spacing-05, 8px);

      gap: var(--between-components-only-spacing-05, 8px);

      ${responsive.md`
      `}
    }
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  padding: var(--between-components-modules-spacing-1, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--between-components-only-spacing-075, 12px);
  align-self: stretch;
  border-radius: var(--between-components-only-spacing-025, 4px);
  background: var(--solid-neutral-white, #fff);

  h3 {
    color: #142b6f;
    ${Font.circular};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    flex: 1 0 0;
    align-self: stretch;

    padding-bottom: 12px;
    border-bottom: 0.5px solid var(--solid-neutral-cool-100, #c3cfd5);
  }
`;

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--between-components-modules-spacing-1, 16px);
  align-self: stretch;

  ${responsive.md`
    flex-direction: row;
  `}
`;

const OrderDetailsSection = styled.div`
  display: flex;
  padding: var(--between-components-modules-spacing-1, 16px);
  flex-direction: column;
  align-items: stretch;
  gap: var(--between-components-modules-spacing-1, 16px);
  flex: 1 0 0;

  border-radius: var(--between-components-only-spacing-025, 4px);
  background: var(--solid-neutral-white, #fff);

  h3 {
    color: #0c2b73;
    text-align: left;
    ${Font.circular};
    font-style: normal;
    font-weight: 450;

    border-bottom: 0.5px solid var(--solid-neutral-cool-100, #c3cfd5);
    padding-bottom: var(--between-components-modules-spacing-1, 16px);

    font-size: 16px;
    line-height: 24px; /* 150% */

    ${responsive.md`
      font-size: 18px;
    line-height: 26px; /* 144.444% */
    `}
  }

  p {
    color: #142b6f;

    ${Font.dutch};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }

  img {
    height: 36px;
    align-self: flex-start;
  }
`;

const OrderDetails = ({
  orderNumber,
  cartProducts,
  paymentType,
  shippingAddress,
}) => {
  const orderItemQuantity = cartProducts.reduce(
    (total, item) => total + item.quantity,
    0,
  );

  let paymentMethodType, paymentMethodLogo;
  switch (paymentType) {
    case "link":
      paymentMethodType = "Link";
      paymentMethodLogo = paymentLogos.link;
      break;
    case "apple_pay":
      paymentMethodType = "Apple Pay";
      paymentMethodLogo = paymentLogos.apple_pay;
      break;
    case "google_pay":
      paymentMethodType = "Google Pay";
      paymentMethodLogo = paymentLogos.google_pay;
      break;
    case "card":
      paymentMethodType = "Card";
      paymentMethodLogo = paymentLogos.card;
      break;
    default:
      paymentMethodType = "";
      paymentMethodLogo = "";
  }

  const formatAddress = (address) => {
    // If address.address is defined, flatten keys
    if (address.address) {
      const { name } = address;
      address = {
        name,
        ...address.address,
      };
    }

    let formattedAddress = `${address.name}<br />${address.line1}<br />`;
    if (address.line2) {
      formattedAddress += `${address.line2}<br />`;
    }
    formattedAddress += `${address.city}, ${address.state} ${address.postal_code}`;
    return formattedAddress;
  };

  return (
    <SummaryContainer>
      <h2>
        Order <span>#{orderNumber}</span>
      </h2>
      <ItemsContainer>
        <h3>
          {intl.t("checkout.confirmation.order-summary.items-header", "Items", {
            count: orderItemQuantity,
          })}
        </h3>
        {cartProducts.map((cartProduct, i) => (
          <OrderItem key={i} cartProduct={cartProduct} />
        ))}
      </ItemsContainer>
      <OrderDetailsContainer>
        <OrderDetailsSection>
          <h3>Shipping</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: formatAddress(shippingAddress),
            }}
          />
        </OrderDetailsSection>
        <OrderDetailsSection>
          <h3>Payment</h3>
          <img src={paymentMethodLogo} alt={`${paymentMethodType} Logo`} />
        </OrderDetailsSection>
      </OrderDetailsContainer>
    </SummaryContainer>
  );
};

export default OrderDetails;
